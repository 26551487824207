<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานการขอ QR เพื่อฝาก PAYMENTGATEWAY
                  </h2>
                </div>
              </div>
            </template>
            <div>
              <card
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    บัญชีฝาก {{ CurcountCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ref_id"
                      >
                        <template v-slot:default="props">
                          {{ props.row.order_number }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          <!-- {{ processRefId(props.row.ref_id) }} -->
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          <badge
                            type="danger"
                            v-if="props.row.status === '0'"
                            >รอดำเนินการฝาก/รอการยินยัน</badge
                          >
                          <badge type="success" v-if="props.row.status === '2'"
                            >สำเร็จ</badge
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="pagination-controls">
                    <el-select
                      v-model="pageSize"
                      @change="handlePageSizeChange"
                    >
                      <el-option
                        v-for="size in pageSizes"
                        :key="size"
                        :label="size === 'All' ? size : size + ' items'"
                        :value="size"
                      ></el-option>
                    </el-select>
                    &nbsp;
                    <span>Page {{ currentPage }}</span>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import moment from "moment";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      searchQuery: "",
      CurcountCount: "0",
      DepositData: [],
      fuseSearch: null,
      currentPage: 1,
      pageSize: 20,
      pageSizes: [20, 50, 100, "All"],
      currentPage1: 1,
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchDeposit();
    }
  },
  methods: {
    handlePageSizeChange() {
      this.currentPage = 1; // Reset to first page
    },
    handleNextPage() {
      const maxPage =
        this.pageSize === "All"
          ? 1
          : Math.ceil(this.DepositData.length / this.pageSize);
      this.currentPage = Math.min(this.currentPage + 1, maxPage);
    },
    moment: function (dateString) {
      return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
    },
    base32Decode(input) {
      const base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
      let bits = "";
      input.split("").forEach((char) => {
        const val = base32chars.indexOf(char);
        bits += val.toString(2).padStart(5, "0");
      });

      let text = "";
      bits.match(/.{1,8}/g).forEach((byte) => {
        text += String.fromCharCode(parseInt(byte, 2));
      });

      return text;
    },
    processRefId(refId) {
      // Check if refId is provided
      if (!refId) {
        return ""; // Return empty or any default value you consider appropriate
      }

      let index = refId.indexOf("GA4");
      if (index === -1) {
        // If "GA4" not found, check for "GA3"
        index = refId.indexOf("GA3");
        if (index === -1) {
          // If neither "GA4" nor "GA3" is found, handle accordingly
          return ""; // Or any default value you consider appropriate
        }
      }

      // Extract the part starting from "GA4" or "GA3"
      const encodedPart = refId.substring(index);

      // Decode the extracted part
      return this.base32Decode(encodedPart);
    },
    fetchDeposit() {
      HTTP.get("/bank/Pgateway/Classgate?gwpDeptransaction", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.data) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.DepositData = resp.data.data;
            this.CurcountCount = this.DepositData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.pageSize === "All" ? undefined : start + this.pageSize;
      return this.DepositData.slice(start, end);
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.DepositData.filter((row) =>
        Object.values(row).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      ).slice(this.from, this.to);
    },
  },
};
</script>

<style></style>
