<template>
  <div class="content">
    <base-header>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="SCB"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ scb_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ scb_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนรวม</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ scb_total }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="KBANK"
            type="gradient-green"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ kbank_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ kbank_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนรวม</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ kbank_total }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="TrueMoneywallet"
            type="gradient-orange"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ truewallet_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ truewallet_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนรวม</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ truewallet_total }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="manual credit"
            type="gradient-red"
            :sub-title="sum"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ manual_credit }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="GATEWAYPAYMENT"
            type="gradient-yellow"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ gwp_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ gwp_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนรวม</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ gwp_total }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="ยกเลิกถอน"
            type="gradient-yellow"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ withdraw_failed }}
              </div>
            </template>
          </stats-card>
          <stats-card
            title="ถอนมือ"
            type="gradient-pink"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ withdraw_manual }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="โบนัสถอน"
            type="gradient-dark"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ bonus }}
              </div>
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card title="รวม" type="gradient-red" icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ withdraw_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ withdraw_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนรวม</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ withdraw_total }}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการถอน
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="fromBank" class="form-control-label"
                    >ธนาคาร</label
                  >
                  <div>
                    <select
                      class="form-control input-group-alternative"
                      id="fromBank"
                      name="fromBank"
                      @change="onBankChange($event)"
                      v-model="FilterBank"
                    >
                      <option selected value="All">-- ทั้งหมด --</option>
                      <option value="SCB">SCB</option>
                      <option value="KBANK">KBANK</option>
                      <option value="TMN">True Money wallet</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
                <!-- {{ searchQuery }} -->
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-danger"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการถอนทั้งหมด {{ WithdrawCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="สมาชิก"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-mobile-alt text-dark mr-2"></i>
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ checkCredit(props.row.credit) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="โบนัส"
                      >
                        <template v-slot:default="props">
                          {{
                            checkBonus(props.row.credit, props.row.credit_up)
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="ยอดสุทธิ"
                      >
                        <template v-slot:default="props">
                          {{ checkCredit(props.row.credit_up) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="ถอนเข้า"
                      >
                        <template v-slot:default="props">
                          <img
                            :src="checkBankImg(props.row.accountName)"
                            width="30"
                            alt=""
                            srcset=""
                          />
                          <br />
                          ธนาคาร{{
                            props.row.accountName === "truewallet"
                              ? "ทรูมันนี่วอลเล็ท"
                              : props.row.accountName
                          }}
                          <br />
                          {{ props.row.accountNumber }}
                          <br />
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ช่องทาง"
                      >
                        <template v-slot:default="props">
                          <badge type="default">{{ props.row.fromBank }}</badge>
                          <br />
                          <badge
                            type="danger"
                            v-if="props.row.fromBank === 'SYSTEM'"
                            >{{ props.row.admin }}</badge
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="ref"
                      >
                        <template v-slot:default="props">
                          {{ props.row.ref }}
                          <br />
                          <badge
                            type="secondary"
                            v-if="props.row.status === '0' && props.row.fromBank === 'GWP' &&  props.row.description != '-' "
                            >{{ props.row.description }}</badge
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="สถานะ"
                      >
                        <template v-slot:default="props">
                          <badge type="success" v-if="props.row.status === '0'"
                            >สำเร็จ</badge
                          >
                          <badge
                            type="warning"
                            v-else-if="props.row.status === '1'"
                            >รออนุมัติ</badge
                          >
                          <badge type="danger" v-else>ยกเลิก</badge>
                          <br />
                          <badge
                            type="secondary"
                            v-if="props.row.status === '2'"
                            >{{ props.row.description }}</badge
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "../../../components/Modal.vue";
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { HTTP } from "@/services/http-common";

var moment = require("moment");

export default {
  components: {
    modal,
    flatPicker,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      truewallet_fee: "",
      kbank_daily: "",
      kbank_monthly: "",
      kbank_total: "",
      manual_credit: "",
      scb_daily: "",
      scb_monthly: "",
      scb_total: "",
      gwp_daily: "",
      gwp_monthly: "",
      gwp_total: "",
      truewallet_daily: "",
      truewallet_monthly: "",
      truewallet_total: "",
      withdraw_daily: "",
      withdraw_failed: "",
      withdraw_manual: "",
      withdraw_monthly: "",
      withdraw_total: "",
      bonus: "",
      loading: false,
      WithdrawCount: "",
      FilterBank: "All",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "credit",
        "admin",
        "accountNumber",
        "accountName",
        "fromBank",
        "phone",
        "time",
        "date",
        "description",
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      BankImg: {
        bbl: require("../../../assets/images/bank/bangkok.svg"),
        kbank: require("../../../assets/images/bank/kbank.svg"),
        ktb: require("../../../assets/images/bank/krungthai.svg"),
        tmb: require("../../../assets/images/bank/tmb.svg"),
        scb: require("../../../assets/images/bank/scb.svg"),
        bay: require("../../../assets/images/bank/krungsri.svg"),
        gsb: require("../../../assets/images/bank/aomsin.svg"),
        baac: require("../../../assets/images/bank/baac.svg"),
        tbank: require("../../../assets/images/bank/tnc.svg"),
        ghb: require("../../../assets/images/bank/ghb.png"),
        scbt: require("../../../assets/images/bank/scbt.png"),
        ttb: require("../../../assets/images/bank/ttb.png"),
        city: require("../../../assets/images/bank/city.png"),
        cimb: require("../../../assets/images/bank/cimb.png"),
        uob: require("../../../assets/images/bank/uob.png"),
        islamic: require("../../../assets/images/bank/islamic.png"),
        tisco: require("../../../assets/images/bank/tisco.png"),
        kkp: require("../../../assets/images/bank/kkp.png"),
        icbc: require("../../../assets/images/bank/icbc.png"),
        thaicredit: require("../../../assets/images/bank/thaicredit.png"),
        lh: require("../../../assets/images/bank/lh.svg"),
        truewallet: require("../../../assets/images/bank/truewallet.png"),
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    onBankChange: function (event) {
      console.log(event.target.value);
      this.FilterBank = event.target.value;
      this.fetchAllData();
    },
    fetchAllData() {
      this.loading = true;
      // HTTP.get("/admin/withdraw_log", {
      HTTP.get("/admin/withdraw_log?bank=" + this.FilterBank, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = resp.data.data;
            this.scb_daily = resp.data.scb_daily;
            this.scb_monthly = resp.data.scb_monthly;
            this.scb_total = resp.data.scb_total;
            this.gwp_daily = resp.data.gwp_daily;
            this.gwp_monthly = resp.data.gwp_monthly;
            this.gwp_total = resp.data.gwp_total;
            this.truewallet_daily = resp.data.truewallet_daily;
            this.truewallet_monthly = resp.data.truewallet_monthly;
            this.truewallet_total = resp.data.truewallet_total;
            this.withdraw_daily = resp.data.withdraw_daily;
            this.withdraw_failed = resp.data.withdraw_failed;
            this.withdraw_manual = resp.data.withdraw_manual;
            this.withdraw_monthly = resp.data.withdraw_monthly;
            this.withdraw_total = resp.data.withdraw_total;
            this.bonus = resp.data.bonus;
            this.kbank_daily = resp.data.kbank_daily;
            this.kbank_monthly = resp.data.kbank_monthly;
            this.kbank_total = resp.data.kbank_total;
            this.manual_credit = resp.data.manual_credit;
            // this.bonus = resp.datcredit_up -= props.row.credit;

            this.WithdrawCount = this.tableData.length;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    checkBonus(credit, credit_up) {
      return (Math.round((credit_up - credit) * 100) / 100).toFixed(2);
    },
    checkCredit(credit) {
      return (Math.round(credit * 100) / 100).toFixed(2);
    },
    checkBankImg(bank) {
      if (bank === "กรุงเทพ") return this.BankImg.bbl;
      if (bank === "กสิกรไทย") return this.BankImg.kbank;
      if (bank === "กรุงไทย") return this.BankImg.ktb;
      if (bank === "ทหารไทยธนชาต") return this.BankImg.tmb;
      if (bank === "ไทยพาณิชย์") return this.BankImg.scb;
      if (bank === "ธกส") return this.BankImg.baac;
      if (bank === "กรุงศรีฯ") return this.BankImg.bay;
      if (bank === "ออมสิน") return this.BankImg.gsb;
      if (bank === "ธอส") return this.BankImg.ghb;
      if (bank === "แสตนดาร์ดชาร์เตอร์") return this.BankImg.scbt;
      if (bank === "ซิตี้แบงค์") return this.BankImg.city;
      if (bank === "ซีไอเอ็มบีไทย") return this.BankImg.cimb;
      if (bank === "ยูโอบี") return this.BankImg.uob;
      if (bank === "อิสลาม") return this.BankImg.islamic;
      if (bank === "ทิสโก้") return this.BankImg.tisco;
      if (bank === "เกียรตินาคินภัทร") return this.BankImg.kkp;
      if (bank === "ไอซีบีซี") return this.BankImg.icbc;
      if (bank === "ไทยเครดิต") return this.BankImg.thaicredit;
      if (bank === "LHBANK") return this.BankImg.lh;
      if (bank === "truewallet") return this.BankImg.truewallet;
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
